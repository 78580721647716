import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import joinAsHospital from "../../assets/landing/join-as-hospital-img.svg";
import { useNavigate } from "react-router-dom";

const JoinAsHospital = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <Container maxWidth="xl">
          <Box
            sx={{
              padding: {
                xs: "30px 0",
                sm: "30px 0",
                md: "30px 0",
                lg: "80px 0 30px",
                xl: "100px 0 45px",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "50%",
                  md: "620px",
                  lg: "620px",
                  xl: "620px",
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={joinAsHospital}
                alt=""
                className="join-hospital-img-1"
              />
            </Box>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "50%",
                  md: "540px",
                  lg: "540px",
                  xl: "540px",
                },
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "22px",
                    md: "24px",
                    lg: "35px",
                    xl: "35px",
                  },
                  fontWeight: 600,
                  lineHeight: {
                    xs: "30px",
                    sm: "38px",
                    md: "40px",
                    lg: "49px",
                    xl: "49px",
                  },
                  color: "#0D2645",
                }}
                variant="h2"
              >
                Streamline Your
                <Typography
                  sx={{
                    color: "#38879F",
                  }}
                  variant="span"
                >
                  Staffing:{" "}
                </Typography>{" "}
                Manage Job Openings{" "}
                <Typography
                  variant="span"
                  sx={{
                    color: "#38879F",
                  }}
                >
                  Effortlessly
                </Typography>
              </Typography>
              <Typography
                sx={{
                  margin: "20px 0 50px",
                  color: "grey",
                  fontWeight: 400,
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "18px",
                    xl: "18px",
                  },
                  lineHeight: {
                    xs: "24px",
                    sm: "26px",
                    md: "27px",
                    lg: "29px",
                    xl: "29px",
                  },
                }}
              >
                Transparent Care simplifies the recruitment process, allowing
                you to focus on delivering exceptional healthcare. Experience
                hassle-free staffing with solutions designed to meet your
                facility's unique needs.
              </Typography>
              <Button
                sx={{
                  height: "65px",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "300px",
                    lg: "300px",
                    xl: "300px",
                  },
                  borderRadius: "10px",
                  border: "1px solid #38879F",
                  background: "#38879F",
                  fontSize: "18px",
                  lineHeight: "21.6px",
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  boxShadow: " 0 4px 16px 0 #00000026",
                  "&:hover": {
                    border: "1px solid #38879F",
                    background: "#38879F",
                  },
                }}
                onClick={() => {
                  localStorage.setItem("userType", "hospital");
                  navigate("/hospital/signup");
                }}
              >
                Join as Hospital
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default JoinAsHospital;
