import React, { useRef, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import posterImages from "../../assets/landing/poster-img-video.png";
import { useNavigate } from "react-router-dom";
import pauseIcon from "../../assets/icons/pause.svg";

const BannerSection = (props) => {
  const { homeID } = props;
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  console.log(isPlaying, "isPlaying");
  const togglePlay = () => {
    debugger;
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };
  return (
    <Box
      id={homeID}
      sx={{
        scrollMarginTop: "120px",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            padding: {
              xs: "30px 0",
              sm: "30px 0",
              md: "30px 0",
              lg: "30px 0 40px",
              xl: "30px 0 40px",
            },
            marginTop: {
              xs: "85px",
              sm: "100px",
              md: "100px",
              lg: "120px",
              xl: "120px",
            },
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            container
            item
          >
            <Grid
              sx={{
                display: {
                  xs: "block",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
                margin: {
                  xs: "0 auto 15px",
                  sm: "0 auto 15px",
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
                position: "relative",
              }}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
              <video
                muted
                style={{ borderRadius: "26px", cursor: "pointer" }}
                id="my-video"
                className="video-js vjs-theme-fantasy"
                controls
                preload="auto"
                width="100%"
                height="220"
                poster={posterImages}
                data-setup="{}"
                ref={videoRef}
                autoPlay
              >
                <source
                  src="https://ricardo-dev-api.s3.ca-central-1.amazonaws.com/uploads/asset/40420311-4bba-4be5-8620-4841052a1c38.v2+%281%29+1.mp4"
                  type="video/mp4"
                />
              </video>
              <button className="play-puse-button" onClick={togglePlay}>
                {isPlaying ? (
                  <img
                    src={pauseIcon}
                    alt="Pause"
                    style={{ width: 24, height: 24 }}
                  />
                ) : null}
              </button>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box
                className="responsive-grid-banner"
                sx={{
                  textAlign: "left",
                  paddingRight: {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: "80px",
                    xl: "19%",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "38px",
                      sm: "32px",
                      md: "42px",
                      lg: "48px",
                      xl: "54px",
                    },
                    fontWeight: 700,
                    lineHeight: {
                      xs: "53px",
                      sm: "46px",
                      md: "48px",
                      lg: "70px",
                      xl: "76px",
                    },
                    color: "#0D2645",
                  }}
                  variant="h1"
                >
                  <Typography
                    sx={{
                      color: "#38879F",
                    }}
                    variant="span"
                  >
                    Optimize
                  </Typography>{" "}
                  Your Healthcare{" "}
                  <Typography
                    variant="span"
                    sx={{
                      color: "#38879F",
                    }}
                  >
                    Recruitment
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    paddingLeft: "20px",
                    margin: "24px 0",
                    color: "grey",
                    fontWeight: 400,
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    lineHeight: {
                      xs: "26px",
                      sm: "26px",
                      md: "29px",
                      lg: "29px",
                      xl: "29px",
                    },
                    borderLeft: "3px solid #38879F",
                  }}
                >
                  Simplify healthcare recruitment with Transparent Care.
                  Hospitals cut costs while healthcare professionals receive
                  fair compensation. Our platform connects you with vetted,
                  reliable talent—efficient, open, and cost-effective staffing
                  made easy.
                </Typography>
                <Button
                  sx={{
                    height: "65px",
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "300px",
                      lg: "300px",
                      xl: "300px",
                    },
                    borderRadius: "10px",
                    border: "1px solid #38879F",
                    background: "#38879F",
                    fontSize: "18px",
                    lineHeight: "21.6px",
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    boxShadow: " 0 4px 16px 0 #00000026",
                    "&:hover": {
                      border: "1px solid #38879F",
                      background: "#38879F",
                    },
                  }}
                  onClick={() => {
                    navigate("/role-selection", {
                      state: {
                        type: "signup",
                      },
                    });
                  }}
                >
                  Register Now
                </Button>
              </Box>
            </Grid>
            <Grid
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                  md: "block",
                  lg: "block",
                  xl: "block",
                }, // textAlign:'right'
              }}
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              <video
                muted
                style={{ borderRadius: "26px", cursor: "pointer" }}
                id="my-video"
                className="video-js vjs-theme-fantasy"
                controls
                preload="auto"
                width="527"
                height="659"
                poster={posterImages}
                data-setup="{}"
                autoPlay
                ref={videoRef}
              >
                <source
                  src="https://ricardo-dev-api.s3.ca-central-1.amazonaws.com/uploads/asset/40420311-4bba-4be5-8620-4841052a1c38.v2+%281%29+1.mp4"
                  type="video/mp4"
                />
              </video>
              <button className="play-puse-button" onClick={togglePlay}>
                {isPlaying ? (
                  <img
                    src={pauseIcon}
                    alt="Pause"
                    style={{ width: 24, height: 24 }}
                  />
                ) : null}
              </button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default BannerSection;
