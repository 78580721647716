import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import joinAsNurse from "../../assets/landing/join-as-nurse.svg";
import { useNavigate } from "react-router-dom";

const JoinAsNurse = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <Container maxWidth="xl">
          <Box
            sx={{
              padding: {
                xs: "30px 0",
                sm: "30px 0",
                md: "30px 0",
                lg: "60px 0 120px",
                xl: "95px 0 190px",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            {/*desktop-data-show-but-not-show-on-mobile*/}
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "50%",
                  md: "590px",
                  lg: "590px",
                  xl: "590px",
                },
                textAlign: "left",
                display: {
                  xs: "none",
                  sm: "block",
                  lg: "block",
                  md: "block",
                  xl: "block",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "22px",
                    md: "24px",
                    lg: "35px",
                    xl: "35px",
                  },
                  fontWeight: 600,
                  lineHeight: {
                    xs: "30px",
                    sm: "38px",
                    md: "40px",
                    lg: "49px",
                    xl: "49px",
                  },
                  color: "#0D2645",
                }}
                variant="h3"
              >
                Seamless&nbsp;
                <Typography
                  sx={{
                    color: "#38879F",
                  }}
                  variant="span"
                >
                  Job Search: <br />{" "}
                </Typography>{" "}
                Browse Openings and{" "}
                <Typography
                  variant="span"
                  sx={{
                    color: "#38879F",
                  }}
                >
                  Apply Instantly
                </Typography>
              </Typography>
              <Typography
                sx={{
                  margin: "20px 0 50px",
                  color: "grey",
                  fontWeight: 400,
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "18px",
                    xl: "18px",
                  },
                  lineHeight: {
                    xs: "24px",
                    sm: "26px",
                    md: "27px",
                    lg: "29px",
                    xl: "29px",
                  },
                }}
              >
                Browse real-time openings and apply instantly, with no clickbait
                tactics. Our user-friendly interface allows professionals to
                quickly connect with hospitals, explore opportunities that match
                their skills, and take the next step in their careers.
                Transparent Care makes the job search process smooth and
                efficient, helping you find the perfect position with ease.
              </Typography>
              <Button
                sx={{
                  height: "65px",
                  width: "300px",
                  borderRadius: "10px",
                  border: "1px solid #38879F",
                  background: "#38879F",
                  fontSize: "18px",
                  lineHeight: "21.6px",
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  boxShadow: " 0 4px 16px 0 #00000026",
                  "&:hover": {
                    border: "1px solid #38879F",
                    background: "#38879F",
                  },
                }}
                onClick={() => {
                  localStorage.setItem("userType", "nurse");
                  navigate("/nurse/signup");
                }}
              >
                Join as healthcare staff
              </Button>
            </Box>
            {/*desktop-data-show-but-not-show-on-mobile END*/}
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "50%",
                  lg: "545px",
                  md: "545px",
                  xl: "545px",
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                }}
              >
                <img src={joinAsNurse} alt="" className="join-nurse-img" />
              </Box>
            </Box>
            {/*desktop-data-NOT show-but-show-on-mobile Start*/}
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "590px",
                  lg: "590px",
                  xl: "590px",
                },
                textAlign: "left",
                display: {
                  xs: "block",
                  sm: "none",
                  lg: "none",
                  md: "none",
                  xl: "none",
                },
                paddingTop: "30px",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "22px",
                    md: "24px",
                    lg: "35px",
                    xl: "35px",
                  },
                  fontWeight: 600,
                  lineHeight: {
                    xs: "30px",
                    sm: "38px",
                    md: "40px",
                    lg: "49px",
                    xl: "49px",
                  },
                  color: "#0D2645",
                }}
                variant="h3"
              >
                Seamless&nbsp;
                <Typography
                  sx={{
                    color: "#38879F",
                  }}
                  variant="span"
                >
                  Job Search: <br />{" "}
                </Typography>{" "}
                Browse Openings and{" "}
                <Typography
                  variant="span"
                  sx={{
                    color: "#38879F",
                  }}
                >
                  Apply Instantly
                </Typography>
              </Typography>
              <Typography
                sx={{
                  margin: "20px 0 50px",
                  color: "grey",
                  fontWeight: 400,
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "18px",
                    xl: "18px",
                  },
                  lineHeight: {
                    xs: "24px",
                    sm: "26px",
                    md: "27px",
                    lg: "29px",
                    xl: "29px",
                  },
                }}
              >
                Browse real-time openings and apply instantly, with no clickbait
                tactics. Our user-friendly interface allows professionals to
                quickly connect with hospitals, explore opportunities that match
                their skills, and take the next step in their careers.
                Transparent Care makes the job search process smooth and
                efficient, helping you find the perfect position with ease.
              </Typography>
              <Button
                sx={{
                  height: "65px",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "300px",
                    lg: "300px",
                    xl: "300px",
                  },
                  borderRadius: "10px",
                  border: "1px solid #38879F",
                  background: "#38879F",
                  fontSize: "18px",
                  lineHeight: "21.6px",
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  boxShadow: " 0 4px 16px 0 #00000026",
                  "&:hover": {
                    border: "1px solid #38879F",
                    background: "#38879F",
                  },
                }}
              >
                Join as healthcare staff
              </Button>
            </Box>
            {/*desktop-data-NOT show-but-show-on-mobile END*/}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default JoinAsNurse;
