import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import whoWeAreImg from "../../assets/landing/who-we-are-img.svg";

const HowWeWork = (props) => {
  const { howWeWorkID } = props;
  return (
    <>
      <Box
        id={howWeWorkID}
        sx={{
          scrollMarginTop: "90px",
          borderBottom: "1px solid #38879F80",
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              padding: {
                xs: "60px 0",
                sm: "60px 0",
                md: "30px 0",
                lg: "120px 0 70px",
                xl: "120px 0 70px",
              },
            }}
          >
            <Grid
              sx={{
                paddingBottom: "42px",
              }}
              container
              item
            >
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "38px",
                      sm: "38px",
                      md: "36px",
                      lg: "51px",
                      xl: "51px",
                    },
                    fontWeight: 700,
                    lineHeight: {
                      xs: "55px",
                      sm: "55px",
                      md: "46px",
                      lg: "55px",
                      xl: "55px",
                    },
                    color: "#0D2645",
                    paddingBottom: "20px",
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      margin: "auto",
                      height: "2px",
                      width: "24px",
                      background: "#38879F",
                    },
                  }}
                  variant="h2"
                >
                  How We{" "}
                  <Typography
                    variant="span"
                    sx={{
                      color: "#38879F",
                    }}
                  >
                    Work
                  </Typography>
                  ?
                </Typography>
                <Typography
                  sx={{
                    margin: "24px 0 0",
                    color: "#696462",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "24px",
                  }}
                >
                  Transparent Care leverages cutting-edge technology to link
                  hospitals directly with nurses, tailoring staffing solutions
                  to meet specific needs.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              container
              item
            >
              {/*this section show only for and laptop start*/}
              <Grid
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  paddingRight: "32px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    paddingBottom: "90px",
                  }}
                >
                  <Box
                    sx={{
                      width: "calc(100% - 80px)",
                      padding: "0 26px 0 0",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: 700,
                        lineHeight: "26px",
                        color: "#0D2645",
                        textAlign: "right",
                      }}
                      variant="h5"
                    >
                      Assessment
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "22px",
                        color: "grey",
                        textAlign: "right",
                      }}
                    >
                      Understanding your specific staffing requirements.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      fontSize: "37.5px",
                      lineHeight: "45px",
                      color: "#fff",
                      background: "#38879F",
                    }}
                  >
                    1
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      width: "calc(100% - 80px)",
                      padding: "0 26px 0 0",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: 700,
                        lineHeight: "26px",
                        color: "#0D2645",
                        textAlign: "right",
                      }}
                      variant="h5"
                    >
                      Screening
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "22px",
                        color: "grey",
                        textAlign: "right",
                      }}
                    >
                      Our platform leverages advanced evaluation tools and
                      comprehensive checks to ensure only the most qualified
                      candidates are selected.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      fontSize: "37.5px",
                      lineHeight: "45px",
                      color: "#fff",
                      background: "#38879F",
                    }}
                  >
                    2
                  </Box>
                </Box>
              </Grid>
              {/*this section show only for and laptop end*/}
              <Grid
                sx={{
                  margin: {
                    xs: "auto",
                    sm: "auto",
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                }}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
              >
                <img src={whoWeAreImg} alt="" className="who-we-are-img" />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  paddingLeft: {
                    xs: 0,
                    sm: 0,
                    md: "32px",
                    lg: "32px",
                    xl: "32px",
                  },
                  paddingTop: {
                    xs: "40px",
                    sm: "40px",
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                }}
              >
                {/*this section show only for and laptop Start*/}
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                      sm: "flex",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                    paddingBottom: {
                      xs: "60px",
                      sm: "60px",
                      md: "90px",
                      lg: "90px",
                      xl: "90px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        xs: "60px",
                        sm: "60px",
                        md: "60px",
                        lg: "80px",
                        xl: "80px",
                      },
                      width: {
                        xs: "60px",
                        sm: "60px",
                        md: "60px",
                        lg: "80px",
                        xl: "80px",
                      },
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      fontSize: {
                        xs: "26px",
                        sm: "26px",
                        md: "30px",
                        lg: "37.5px",
                        xl: "37.5px",
                      },
                      lineHeight: {
                        xs: "31px",
                        sm: "31px",
                        md: "36px",
                        lg: "45px",
                        xl: "45px",
                      },
                      color: "#fff",
                      background: "#38879F",
                    }}
                  >
                    1
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xs: "calc(100% - 60px)",
                        sm: "calc(100% - 60px)",
                        md: "calc(100% - 60px)",
                        lg: "calc(100% - 80px)",
                        xl: "calc(100% - 80px)",
                      },
                      padding: {
                        xs: "0 0 0 10px",
                        sm: "0 0 0 10px",
                        md: "0 0 0 25px",
                        lg: "0 0 0 26px",
                        xl: "0 0 0 26px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: 700,
                        lineHeight: "26px",
                        color: "#0D2645",
                        textAlign: "left",
                      }}
                      variant="h5"
                    >
                      Assessment
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "22px",
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      Understanding your specific staffing requirements.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                      sm: "flex",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                    paddingBottom: {
                      xs: "60px",
                      sm: "60px",
                      md: "90px",
                      lg: "90px",
                      xl: "90px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        xs: "60px",
                        sm: "60px",
                        md: "60px",
                        lg: "80px",
                        xl: "80px",
                      },
                      width: {
                        xs: "60px",
                        sm: "60px",
                        md: "60px",
                        lg: "80px",
                        xl: "80px",
                      },
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      fontSize: {
                        xs: "26px",
                        sm: "26px",
                        md: "30px",
                        lg: "37.5px",
                        xl: "37.5px",
                      },
                      lineHeight: {
                        xs: "31px",
                        sm: "31px",
                        md: "36px",
                        lg: "45px",
                        xl: "45px",
                      },
                      color: "#fff",
                      background: "#38879F",
                    }}
                  >
                    2
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xs: "calc(100% - 60px)",
                        sm: "calc(100% - 60px)",
                        md: "calc(100% - 60px)",
                        lg: "calc(100% - 80px)",
                        xl: "calc(100% - 80px)",
                      },
                      padding: {
                        xs: "0 0 0 10px",
                        sm: "0 0 0 10px",
                        md: "0 0 0 25px",
                        lg: "0 0 0 26px",
                        xl: "0 0 0 26px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: 700,
                        lineHeight: "26px",
                        color: "#0D2645",
                        textAlign: "left",
                      }}
                      variant="h5"
                    >
                      Screening
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "22px",
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      Our platform leverages advanced evaluation tools and
                      comprehensive checks to ensure only the most qualified
                      candidates are selected.
                    </Typography>
                  </Box>
                </Box>
                {/*this section show only for and laptop end*/}
                <Box
                  sx={{
                    display: "flex",
                    paddingBottom: {
                      xs: "60px",
                      sm: "60px",
                      md: "90px",
                      lg: "90px",
                      xl: "90px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        xs: "60px",
                        sm: "60px",
                        md: "60px",
                        lg: "80px",
                        xl: "80px",
                      },
                      width: {
                        xs: "60px",
                        sm: "60px",
                        md: "60px",
                        lg: "80px",
                        xl: "80px",
                      },
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      fontSize: {
                        xs: "26px",
                        sm: "26px",
                        md: "30px",
                        lg: "37.5px",
                        xl: "37.5px",
                      },
                      lineHeight: {
                        xs: "31px",
                        sm: "31px",
                        md: "36px",
                        lg: "45px",
                        xl: "45px",
                      },
                      color: "#fff",
                      background: "#38879F",
                    }}
                  >
                    3
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xs: "calc(100% - 60px)",
                        sm: "calc(100% - 60px)",
                        md: "calc(100% - 60px)",
                        lg: "calc(100% - 80px)",
                        xl: "calc(100% - 80px)",
                      },
                      padding: {
                        xs: "0 0 0 10px",
                        sm: "0 0 0 10px",
                        md: "0 0 0 25px",
                        lg: "0 0 0 26px",
                        xl: "0 0 0 26px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: 700,
                        lineHeight: "26px",
                        color: "#0D2645",
                        textAlign: "left",
                      }}
                      variant="h5"
                    >
                      Matching
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "22px",
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      Connecting you with ideal healthcare providers.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        xs: "60px",
                        sm: "60px",
                        md: "60px",
                        lg: "80px",
                        xl: "80px",
                      },
                      width: {
                        xs: "60px",
                        sm: "60px",
                        md: "60px",
                        lg: "80px",
                        xl: "80px",
                      },
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      fontSize: {
                        xs: "26px",
                        sm: "26px",
                        md: "30px",
                        lg: "37.5px",
                        xl: "37.5px",
                      },
                      lineHeight: {
                        xs: "31px",
                        sm: "31px",
                        md: "36px",
                        lg: "45px",
                        xl: "45px",
                      },
                      color: "#fff",
                      background: "#38879F",
                    }}
                  >
                    4
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xs: "calc(100% - 60px)",
                        sm: "calc(100% - 60px)",
                        md: "calc(100% - 60px)",
                        lg: "calc(100% - 80px)",
                        xl: "calc(100% - 80px)",
                      },
                      padding: {
                        xs: "0 0 0 10px",
                        sm: "0 0 0 10px",
                        md: "0 0 0 25px",
                        lg: "0 0 0 26px",
                        xl: "0 0 0 26px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: 700,
                        lineHeight: "26px",
                        color: "#0D2645",
                        textAlign: "left",
                      }}
                      variant="h5"
                    >
                      Feedback
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "22px",
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      Our personalized service means we evolve with our clients
                      to stay up-to-date and competitive.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default HowWeWork;
