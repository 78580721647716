import React from "react";
import { Box, Container, Typography } from "@mui/material";
import bannerImg from "../../assets/landing/banner-img-right.png";
import bannerImageMobile from "../../assets/landing/banner-image-mobile.png";

const WhoWeAre = (props) => {
  return (
    <>
      <Box
        id={props.whoWeID}
        sx={{
          scrollMarginTop: "100px",
          borderBottom: "1px solid #38879F80",
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              padding: {
                xs: "30px 0",
                sm: "30px 0",
                md: "30px 0",
                lg: "0 0 60px",
                xl: "0 0 95px",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "50%",
                  md: "50%",
                  lg: "50%",
                  xl: "50%",
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={bannerImg}
                alt="banner img"
                className="banner-imag-home who-we-are-banner"
              />
              <img
                src={bannerImageMobile}
                alt="banner img"
                className="banner-imag-home banner-img-mobile"
              />
            </Box>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "50%",
                  md: "50%",
                  lg: "50%",
                  xl: "45%",
                },
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "24px",
                    md: "30px",
                    lg: "40px",
                    xl: "51px",
                  },
                  fontWeight: 700,
                  lineHeight: {
                    xs: "30px",
                    sm: "50px",
                    md: "60px",
                    lg: "65px",
                    xl: "71px",
                  },
                  color: "#0D2645",
                }}
                variant="h2"
              >
                Who{" "}
                <Typography
                  sx={{
                    color: "#38879F",
                  }}
                  variant="span"
                >
                  We{" "}
                </Typography>{" "}
                Are
              </Typography>
              <Typography
                sx={{
                  margin: "20px 0 50px",
                  color: "grey",
                  fontWeight: 400,
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "18px",
                    xl: "18px",
                  },
                  lineHeight: {
                    xs: "24px",
                    sm: "26px",
                    md: "27px",
                    lg: "29px",
                    xl: "29px",
                  },
                }}
              >
                “As a dedicated physician with extensive in-hospital experience,
                I founded Transparent Care to enhance efficiency and
                affordability in healthcare staffing. Driven by a commitment to
                improving the work environment for staff and providing hospitals
                with dependable, cost-effective solutions, I used my first-hand
                understanding of the challenges faced by both nurses and
                healthcare institutions to create Transparent Care. Our platform
                is designed to deliver clarity, quality, and financial savings,
                fostering a stronger and more efficient healthcare system.” –
                Dr. de Leon
              </Typography>
              <Typography
                sx={{
                  margin: "0 0 50px",
                  color: "rgba(13, 38, 69, 0.5)",
                  fontWeight: 400,
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "18px",
                    xl: "18px",
                  },
                  lineHeight: {
                    xs: "24px",
                    sm: "26px",
                    md: "27px",
                    lg: "29px",
                    xl: "29px",
                  },
                }}
              >
                {/* Dui sapien eget mi proin. Porttitor lacus luctus accumsan tortor
                    posuere ac ut consequat. Habitasse platea dictumst quisque sagittis.
                    Platea dictumst vestibulum rhoncus est pellentesque elit. Sit amet
                    consectetur adipiscing elit ut aliquam purus sit. Vitae et leo duis
                    ut diam quam nulla porttitor massa. Est pellentesque elit
                    ullamcorper dignissim cras tincidunt lobortis feugiat. */}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default WhoWeAre;
