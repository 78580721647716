import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setNurseMenuSelected,
  setSelectedType,
} from "../../../redux/slices/flags_slice";
import { format } from "date-fns";
import StyledChip from "../../common/styled/chip";
import { getFormattedValue, getShiftNames } from "../../../utils/helper";
import StarIcon from "../../../assets/icons/star.svg";
import BackNavigation from "../../../assets/icons/back-profile.svg";
import ModalConfirmIcon from "../../../assets/icons/modal-confirm.svg";
import {
  ApplyJobService,
  GetNurseJobDetails,
} from "../../../controller/services/nurse/jobs";
import CommonModal from "../../common/modals/commonModal";
import { setBreadcrumb } from "../../../redux/slices/breadcrumb_slice";
import { GetRatings } from "../../../controller/services/common";
import FilledStar from "../../../assets/icons/filled-star.svg";
import BlankStar from "../../../assets/icons/blank-star.svg";
import InfiniteScrollWrapper from "../../common/infinite-scroll";
import { NurseQuestionsArr } from "../../common/modals/ratingModal";
const labelStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "16px",
    sm: "16px",
    md: "18px",
    lg: "18px",
    xl: "18px",
  },
  fontWeight: 700,
  lineHeight: "21.6px",
  color: "#0D2645",
  padding: "10px 0",
};

const valueStyles = {
  fontFamily: "Lato",
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  fontWeight: 400,
  lineHeight: "26px",
  color: "#8b97a6",
  padding: {
    xs: "0 0 10px",
    sm: "10px 0",
    md: "10px 0",
    lg: "10px 0",
    xl: "10px 0",
  },
};

const styles = {
  color: "#0d2645",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "15px",
    xl: "16px",
  },
  lineHeight: "18.5px",
  fontWeight: 500,
  margin: {
    xs: "15px 0 0",
    sm: "15px 0 0",
    md: "15px 0 0",
    lg: "15px 0",
    xl: "15px",
  },
  padding: {
    xs: "5px 20px",
    sm: "5px 20px",
    md: "5px 20px",
    lg: "10px 20px",
    xl: "10px 20px",
  },
  width: {
    xs: "48%",
    sm: "auto",
    md: "auto",
    lg: "auto",
    xl: "auto",
  },
  borderRadius: "10px",
  float: {
    xs: "left",
    sm: "none",
    md: "none",
    lg: "none",
    xl: "none",
  },
};

export default function ApplyJob() {
  const params = useParams();
  const dispatch = useDispatch();
  const [jobData, setJobData] = useState(null);
  const [showHospitalDesc, setShowHospitalDesc] = useState(false);
  const [showRatings, setShowRatings] = useState(false);
  useEffect(() => {
    fetchJobDetails();
  }, []);
  console.log(jobData, "jobData");
  useEffect(() => {
    const arr = [
      {
        title: "Job Board",
        route: "/nurse/dashboard",
      },
      {
        title: jobData?.title,
        route: "",
      },
    ];
    if (jobData?.title) {
      dispatch(setBreadcrumb(arr));
    }
  }, [jobData?.title]);

  const fetchJobDetails = () => {
    dispatch(setLoading(true));
    GetNurseJobDetails(params.id)
      .then((resp) => {
        setJobData(resp.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  if (jobData) {
    return (
      <Box
        sx={{
          padding: {
            xs: "20px 15px",
            sm: "20px",
            md: "30px",
            lg: "30px",
            xl: "30px",
          },
        }}
        maxHeight={"90vh"}
        overflow={"auto"}
      >
        {showRatings ? (
          <RatingsAndReviewsList
            jobData={jobData}
            handleHideRatings={() => {
              setShowRatings(false);
              const arr = [
                {
                  title: "Job Board",
                  route: "/nurse/dashboard",
                },
                {
                  title: jobData?.title,
                  route: `/nurse/dashboard/jobs/${jobData?._id}`,
                },
                {
                  title: jobData?.hospitalDetails?.hospitalName,
                  route: "",
                },
              ];
              if (jobData?.title) {
                dispatch(setBreadcrumb(arr));
              }
            }}
          />
        ) : (
          <>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box
                display={"flex"}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                    xl: "auto",
                  },
                  marginBottom: {
                    xs: "10px",
                    sm: "10px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                  alignItems: {
                    xs: "flex-start",
                    sm: "flex-start",
                    md: "center",
                    lg: "center",
                    xl: "center",
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    fontFamily: "Lato",
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "22px",
                      lg: "24px",
                      xl: "24px",
                    },
                    fontWeight: 700,
                    lineHeight: "28.8px",
                    color: "#0D2645",
                    marginRight: {
                      xs: 0,
                      sm: 0,
                      md: "10px",
                      lg: "10px",
                      xl: "10px",
                    },
                    marginBottom: {
                      xs: "10px",
                      sm: "10px",
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                  }}
                >
                  {jobData?.title}
                </Typography>
                <Chip
                  sx={{
                    background: "#ecf3f6",
                  }}
                  label={
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lato",
                          fontSize: {
                            xs: "10px",
                            sm: "11px",
                            md: "12px",
                            lg: "12px",
                            xl: "12px",
                          },
                          fontWeight: 400,
                          lineHeight: "12px",
                          textAlign: "left",
                          color: "#50647a",
                        }}
                      >
                        Start Date:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Lato",
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "14px",
                            lg: "16px",
                            xl: "16px",
                          },
                          fontWeight: {
                            xs: 600,
                            sm: 500,
                            md: 500,
                            lg: 500,
                            xl: 500,
                          },
                          lineHeight: "19.2px",
                          textAlign: "left",
                          color: "#0D2645",
                        }}
                      >
                        &nbsp;
                        {jobData?.startDate
                          ? format(new Date(jobData?.startDate), "dd MMM, yyyy")
                          : ""}
                      </Typography>
                    </Box>
                  }
                  variant="filled"
                />
              </Box>
              <Box>
                {/* <img
                src={EditJobIcon}
                alt="edit job"
                onClick={() => navigate(`/hospital/edit-job/${jobData?._id}`)}
              /> */}
                <StyledChip
                  variant="outlined"
                  styles={{
                    background: "#F19000",
                    color: "#fff",
                    border: "1px solid #F19000",
                    padding: "5px 10px",
                  }}
                  label={"Available Positions:"}
                  value={jobData?.remainingOpening}
                />
              </Box>
            </Box>
            <Grid container>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Job Offer:"}
                  value={`${getFormattedValue(
                    jobData?.weeklyPay +
                      jobData?.houseAllowance +
                      jobData?.mealAllowance
                  )} /wk`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    xl: "block",
                    lg: "block",
                  },
                }}
                xs={12}
                sm={4}
                md={4}
                lg={3}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.shifts?.map((shift, idx) => {
                    return (
                      <Box
                        sx={{
                          display: "inline-block",
                          width: "auto !important",
                        }}
                        key={shift}
                      >
                        {getShiftNames(shift) +
                          (jobData?.shifts?.length !== idx + 1 ? ", " : "")}
                      </Box>
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Duration:"}
                  value={`${jobData?.duration} Weeks`}
                />
              </Grid>
              <Grid
                sx={{
                  padding: "5px 2px",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    xl: "none",
                    lg: "none",
                  },
                }}
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Shift:"}
                  value={jobData?.shifts?.map((shift, idx) => {
                    return (
                      getShiftNames(shift) +
                      (jobData?.shifts?.length !== idx + 1 ? ", " : "")
                    );
                  })}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Exp. Required:"}
                  value={`${jobData?.experience} Years`}
                />
              </Grid>
              <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Open Positions:"}
                  value={jobData?.numberOfOpenings}
                />
              </Grid>
              {/* <Grid
                sx={{ padding: "5px 2px" }}
                xs={6}
                sm={4}
                md={4}
                lg={2}
                xl={2}
                item
              >
                <StyledChip
                  variant="outlined"
                  styles={{
                    border: "1px solid #d7e7ec",
                  }}
                  label={"Available Positions:"}
                  value={jobData?.remainingOpening}
                />
              </Grid> */}
            </Grid>
            <Divider
              sx={{
                margin: {
                  xs: "10px 0",
                  sm: "10px 0",
                  md: "15px 0",
                  lg: "20px 0",
                  xl: "20px 0",
                },
              }}
            />
            <Grid container item columnSpacing={3}>
              <Grid
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sm={12}
                sx={{
                  height: {
                    xs: "auto",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto",
                  },
                }}
                item
              >
                {showHospitalDesc ? (
                  <HospitalDescription
                    jobData={jobData}
                    handleShowRatings={() => setShowRatings(true)}
                    handleShowHospitalDesc={() => {
                      setShowHospitalDesc(false);
                      const arr = [
                        {
                          title: "Job Board",
                          route: "/nurse/dashboard",
                        },
                        {
                          title: jobData?.title,
                          route: "",
                        },
                      ];
                      if (jobData?.title) {
                        dispatch(setBreadcrumb(arr));
                      }
                    }}
                  />
                ) : (
                  <JobDescription
                    jobData={jobData}
                    handleShowHospitalDesc={() => {
                      setShowHospitalDesc(true);
                      const arr = [
                        {
                          title: "Job Board",
                          route: "/nurse/dashboard",
                        },
                        {
                          title: jobData?.title,
                          route: `/nurse/dashboard/jobs/${jobData?._id}`,
                          func: () => {
                            setShowHospitalDesc(false);
                            const arr = [
                              {
                                title: "Job Board",
                                route: "/nurse/dashboard",
                              },
                              {
                                title: jobData?.title,
                                route: "",
                              },
                            ];
                            if (jobData?.title) {
                              dispatch(setBreadcrumb(arr));
                            }
                          },
                        },
                        {
                          title: jobData?.hospitalDetails?.hospitalName,
                          route: "",
                        },
                      ];
                      if (jobData?.title) {
                        dispatch(setBreadcrumb(arr));
                      }
                    }}
                  />
                )}
                {/* <PendingCandidates jobData={jobData} /> */}
                {/* <ViewApplicantDetails /> */}
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    );
  }
  return <></>;
}

const HospitalDescription = ({
  jobData,
  handleShowHospitalDesc,
  handleShowRatings,
}) => {
  return (
    <>
      <Box maxHeight={"60vh"} overflow={"auto"}>
        <Box textAlign={"left"}>
          <Typography
            display={"flex"}
            alignItems={"center"}
            marginBottom={"10px"}
            onClick={handleShowHospitalDesc}
            sx={{
              cursor: "pointer",
            }}
          >
            <img src={BackNavigation} alt="back" />
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "19.2px",
                color: "#38879F",
                marginLeft: "5px",
              }}
            >
              Back
            </span>
          </Typography>
          <Typography sx={{ ...labelStyles }}>Posted By</Typography>
          <Box display={"flex"} alignItems={"center"}>
            <img
              className="hospital-log-img"
              src={jobData?.hospitalDetails?.hospitalLogo}
              width={132}
              height={132}
              style={{ borderRadius: "50%" }}
            />
            <Box
              sx={{
                marginLeft: {
                  xs: "10px",
                  sm: "10px",
                  md: "15px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "20px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: 700,
                  lineHeight: {
                    xs: "21px",
                    sm: "21px",
                    md: "25px",
                    lg: "28.8px",
                    xl: "28.8px",
                  },
                  color: "#0d2645",
                }}
              >
                {jobData?.hospitalDetails?.hospitalName}
              </Typography>
              <Box display={"flex"} alignItems={"center"} margin={"5px 0"}>
                <img src={StarIcon} alt="star" />
                <Typography
                  color="#0d2645"
                  sx={{
                    cursor: "pointer",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                  lineHeight={"21px"}
                  fontWeight={500}
                  marginLeft={"5px"}
                  onClick={() => {
                    if (jobData?.hospitalDetails?.rating > 0) {
                      handleShowRatings();
                    }
                  }}
                >
                  {jobData?.hospitalDetails?.rating} (
                  {jobData?.hospitalDetails?.ratingCount || 0}{" "}
                  {jobData?.hospitalDetails?.ratingCount > 1
                    ? "reviews"
                    : "review"}
                  )
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Location</Typography>
          <Typography sx={{ ...valueStyles }}>
            {jobData?.hospitalDetails?.address},{" "}
            {jobData?.hospitalDetails?.city}, {jobData?.hospitalDetails?.state}
          </Typography>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Description</Typography>
          <Typography sx={{ ...valueStyles }}>
            {jobData?.description}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
const JobDescription = ({ jobData, handleShowHospitalDesc }) => {
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleApplyJob = () => {
    const payload = {
      jobId: params.id,
    };
    dispatch(setLoading(true));
    ApplyJobService(payload)
      .then((resp) => {
        setSuccessModalVisible(true);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <>
      <Box
        className={"height-adjust-screen-size"}
        sx={{
          height: {
            xs: "50vh",
            sm: "55vh",
            md: "60vh",
            lg: "40vh",
            xl: "55vh",
          },
        }}
        overflow={"auto"}
      >
        {" "}
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Posted By</Typography>
          <Box display={"flex"} alignItems={"center"}>
            <img
              className="hospital-log-img"
              src={jobData?.hospitalDetails?.hospitalLogo}
              width={132}
              height={132}
              style={{ borderRadius: "50%" }}
            />
            <Box marginLeft={"20px"}>
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "20px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: 700,
                  lineHeight: {
                    xs: "21px",
                    sm: "22px",
                    md: "25px",
                    lg: "28.8px",
                    xl: "28.8px",
                  },
                  color: "#0d2645",
                }}
              >
                {jobData?.hospitalDetails?.hospitalName}
              </Typography>
              <Box display={"flex"} alignItems={"center"} margin={"5px 0"}>
                <img src={StarIcon} alt="star" />
                <Typography
                  color="#0d2645"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    lineHeight: {
                      xs: "18px",
                      sm: "18px",
                      md: "21px",
                      lg: "21px",
                      xl: "21px",
                    },
                  }}
                  fontWeight={500}
                  marginLeft={"5px"}
                >
                  {jobData?.hospitalDetails?.rating} (
                  {jobData?.hospitalDetails?.ratingCount || 0}{" "}
                  {jobData?.hospitalDetails?.ratingCount > 1
                    ? "reviews"
                    : "review"}
                  )
                </Typography>
              </Box>
              <Link
                to=""
                style={{ color: "#38879F" }}
                onClick={handleShowHospitalDesc}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: 700,
                    lineHeight: "21.6px",
                  }}
                >
                  {"View Hospital Profile >"}
                </Typography>{" "}
              </Link>
            </Box>
          </Box>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Location</Typography>
          <Typography sx={{ ...valueStyles }}>
            {jobData?.hospitalDetails?.address},{" "}
            {jobData?.hospitalDetails?.city}, {jobData?.hospitalDetails?.state}
          </Typography>
        </Box>
        <Box textAlign={"left"}>
          <Typography sx={{ ...labelStyles }}>Job Description</Typography>
          <Typography sx={{ ...valueStyles }}>
            {jobData?.description}
          </Typography>
        </Box>
        <Box textAlign={"left"}>
          <Typography
            sx={{
              ...labelStyles,
              marginBottom: {
                xs: 0,
                sm: 0,
                md: "10px",
                lg: "10px",
                xl: "10px",
              },
            }}
          >
            Paycheck
          </Typography>
          <Box
            border={"1.5px solid #99c1cd"}
            padding={"20px"}
            borderRadius={"20px"}
          >
            <Box
              padding={"0 0 20px 0"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  fontWeight: 500,
                  lineHeight: "19.2px",
                  color: "#54677c",
                }}
              >
                Weekly Earnings
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "18px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: 700,
                  lineHeight: "21.6px",
                  color: "#0D2645",
                }}
              >
                {getFormattedValue(jobData?.weeklyPay)}
                <Typography
                  variant="span"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "16.8px",
                    color: "#8b97a6",
                  }}
                >
                  /wk
                </Typography>
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"20px 0 5px 0"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Travel Per Diems
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: 700,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  {getFormattedValue(
                    jobData?.houseAllowance + jobData?.mealAllowance
                  )}
                  <Typography
                    variant="span"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                        lg: "14px",
                        xl: "14px",
                      },
                      fontWeight: 500,
                      lineHeight: "16.8px",
                      color: "#8b97a6",
                    }}
                  >
                    /wk
                  </Typography>
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"5 0"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Meals
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  {getFormattedValue(jobData?.mealAllowance)}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                padding={"5px 0 20px 0"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Housing
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: 500,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  {getFormattedValue(jobData?.houseAllowance)}
                </Typography>
              </Box>
              <Divider />
              <Box
                paddingTop={"20px"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontWeight: 500,
                    lineHeight: "19.2px",
                    color: "#54677c",
                  }}
                >
                  Total Weekly Payment
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: 700,
                    lineHeight: "21.6px",
                    color: "#0D2645",
                  }}
                >
                  {getFormattedValue(
                    jobData?.weeklyPay +
                      jobData?.houseAllowance +
                      jobData?.mealAllowance
                  )}
                  <Typography
                    variant="span"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                        lg: "14px",
                        xl: "14px",
                      },
                      fontWeight: 500,
                      lineHeight: "16.8px",
                      color: "#8b97a6",
                    }}
                  >
                    /wk
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box textAlign={"right"}>
        <Button
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
              md: "22%",
              lg: "22%",
              xl: "22%",
            },
            height: "55px",
            marginTop: "15px",
            background: "#38879f",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "10px",
            "&.Mui-disabled": {
              background: "#adcdd7",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#38879f",
            },
          }}
          disabled={jobData?.remainingOpening == 0}
          onClick={handleApplyJob}
        >
          Send Application
        </Button>
      </Box>
      <CommonModal
        title="Application sent
        successfully"
        open={successModalVisible}
        icon={ModalConfirmIcon}
        onClose={() => {
          setSuccessModalVisible(false);
          dispatch(setNurseMenuSelected(1));
          dispatch(setSelectedType("pending"));
          navigate("/nurse/applied-jobs");
        }}
        isSingleButton
        singleBtnText={"Done"}
      />
    </>
  );
};

export const RatingsAndReviewsList = ({
  userDetails,
  handleHideRatings,
  type,
  jobData,
  handleShowUser,
}) => {
  console.log(userDetails, "userDetails");
  const [page, setPage] = useState(1);
  const [ratingsList, setRatingsList] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [hasNextResult, setHasNextResult] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    GetRatingsList();
  }, [page]);
  const GetRatingsList = () => {
    dispatch(setLoading(true));
    const payload = {
      userId: jobData?.hospitalDetails?._id,
      limit: 10,
      page: page,
    };
    GetRatings(payload)
      .then((resp) => {
        if (page == 1) {
          setRatingsList(resp.data.list);
        } else {
          setRatingsList((prev) => [...prev, ...resp.data.list]);
        }
        const sortedArr = resp.data?.feedbackData?.sort(
          (a, b) => a.questionNumber - b.questionNumber
        );
        setFeedbackData(sortedArr);
        setHasNextResult(resp.data?.hasResult);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Box textAlign={"left"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Typography
            display={"flex"}
            alignItems={"center"}
            marginBottom={"20px"}
            onClick={handleHideRatings}
            sx={{
              cursor: "pointer",
            }}
          >
            <img src={BackNavigation} alt="back" onClick={() => {}} />
            <span
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "19.2px",
                color: "#38879F",
                marginLeft: "5px",
              }}
            >
              Back
            </span>
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <img
          alt={""}
          src={jobData?.hospitalDetails?.hospitalLogo}
          width={132}
          style={{ borderRadius: "25px" }}
        />
        <Box marginLeft={"20px"}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "28.8px",
              color: "#0d2645",
            }}
          >
            {jobData?.hospitalDetails?.hospitalName}
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"space-evenly"}
            sx={{ width: "100%" }}
          >
            <img src={StarIcon} alt="star" />
            <Typography
              color="#0d2645"
              fontSize={"14px"}
              lineHeight={"21px"}
              fontWeight={500}
              marginLeft={"5px"}
            >
              {`${jobData?.hospitalDetails?.rating} (${
                jobData?.hospitalDetails?.ratingCount || 0
              } ${
                jobData?.hospitalDetails?.ratingCount > 1 ? "reviews" : "review"
              })`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "23px",
            textAlign: "left",
            color: "#000",
            margin: "15px 0",
            fontWeight: 600,
          }}
        >
          Ratings
        </Typography>
        <Box padding={1}>
          {NurseQuestionsArr?.map((item) => {
            return (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                margin={1}
                flexDirection={{
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "column",
                  xl: "row",
                }}
              >
                <Typography>{item?.title}</Typography>
                <Box
                  marginTop={{
                    sm: "10px",
                    xs: "10px",
                    md: "10px",
                    lg: "10px",
                    xl: 0,
                  }}
                >
                  {[1, 2, 3, 4, 5].map((i) => {
                    if (i <= feedbackData[item.step]?.averageRating) {
                      return (
                        <img
                          src={FilledStar}
                          width={25}
                          style={{ marginRight: "10px" }}
                          key={i}
                        />
                      );
                    }
                    return (
                      <img
                        key={i}
                        src={BlankStar}
                        width={25}
                        style={{ marginRight: "10px" }}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "23px",
            textAlign: "left",
            color: "#000",
            margin: "15px 0",
            fontWeight: 600,
          }}
        >
          Reviews
        </Typography>
        <InfiniteScrollWrapper
          handleSetPage={() => setPage((prev) => prev + 1)}
          hasResult={hasNextResult}
        >
          {ratingsList?.map((item) => {
            return (
              <Box
                sx={{
                  background: "#ecf3f6",
                  border: "1.5px solid #92bcca",
                  marginBottom: "20px",
                  padding: "20px",
                  borderRadius: "20px",
                }}
                key={item?._id}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"15%"}
                  >
                    {[1, 2, 3, 4, 5].map((i) => {
                      if (i <= item.rating) {
                        return (
                          <img
                            alt=""
                            src={FilledStar}
                            // onClick={() => handleSetRating(item.step, i)}
                            width={20}
                            style={{ marginRight: "10px" }}
                            key={i}
                          />
                        );
                      }
                      return (
                        <img
                          alt=""
                          src={BlankStar}
                          // onClick={() => handleSetRating(item.step, i)}
                          width={20}
                          style={{ marginRight: "10px" }}
                          key={i}
                        />
                      );
                    })}
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "14.2px",
                      }}
                      color="primary"
                    >
                      {item.rating}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14.4px",
                      textAlign: "left",
                      color: "#8391a0",
                    }}
                  >
                    {item?.createdAt
                      ? format(new Date(item?.createdAt), "dd MMM, yyyy")
                      : ""}
                  </Typography>
                </Box>
                {item?.review && item?.review != " " && (
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "26px",
                        textAlign: "left",
                      }}
                      color="primary"
                    >
                      {item.review}
                    </Typography>
                  </Box>
                )}
                {/* <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.reviewer?.profilePicture}
                      alt="profile-pic"
                      width={40}
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      sx={{
                        marginLeft: "10px",
                        fontFamily: "Lato",
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "19.2px",
                        textAlign: "left",
                        borderRight: "1px solid black",
                        paddingRight: "25px",
                      }}
                      color="primary"
                    >
                      {item.reviewer?.firstName +
                        " " +
                        (item.reviewer?.lastName || "")}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      marginLeft: "20px",
                      fontFamily: "Lato",
                      fontSize: "18px",
                      fontWeight: 500,
                      lineHeight: "19.2px",
                      textAlign: "left",
                    }}
                    color="primary"
                  >
                    Experience: {item.reviewer.experience || 5} yrs
                  </Typography>
                </Box> */}
              </Box>
            );
          })}
        </InfiniteScrollWrapper>
      </Box>
    </Box>
  );
};
